import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, MenuItem, Popover, Stack } from '@mui/material';
import styles from './CompanySelect.module.scss';
import { colors } from '../../../../../config/styles/colors';
import { useMutation } from '@tanstack/react-query';
import { SelectOption } from '../../../../common/Input/SelectInput/SelectInput';
import { DefaultError } from '@tanstack/query-core/build/modern/index';
import axios from '../../../../../libraries/axios/axios';
import { apiRoutes } from '../../../../../config/api/apiRoutes';
import doubleArrowsIcon from '../../../../../assets/icons/double-arrows.svg';
import { useRecoilState } from 'recoil';
import { companyState } from '../../../../../atoms/company/company.atom';
import {
  LocalStorageKey,
  setLocalStorageValue,
} from '../../../../../utils/localStorage/localStorage';
import _ from 'lodash';

export const CompanySelect = () => {
  const [companyStateValue, setCompanyStateValue] =
    useRecoilState(companyState);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null,
  );

  const { mutate } = useMutation<{ data: SelectOption[] }, DefaultError>({
    mutationFn: () => {
      return axios.get(apiRoutes.companies.options);
    },
    onSuccess: (response) => {
      setOptions(response.data);
    },
  });

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    const defaultOption = _.first(options) ?? null;

    if (!defaultOption) {
      return;
    }

    if (!companyStateValue) {
      setSelectedOption(defaultOption);
      setCompanyStateValue(defaultOption.value);
      return;
    }

    setSelectedOption(
      options.find(
        (option) => option.value.toString() === companyStateValue.toString(),
      ) ?? null,
    );
  }, [companyStateValue, options]);

  const handleItemClick = (value: string) => {
    setCompanyStateValue(value);
    setLocalStorageValue(LocalStorageKey.company, value);

    handleClosePopover();

    window.location.reload();
  };

  const handleOpenPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setIsOpen(true);
  };

  const handleClosePopover = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  if (process.env.REACT_APP_PLATFORM_ENABLED !== 'true') {
    return null;
  }

  if (!selectedOption) {
    return null;
  }

  return (
    <>
      <Button
        onClick={(e) => handleOpenPopover(e)}
        size="small"
        className={styles.companySelect}
      >
        <Avatar src={selectedOption.image} className={styles.companyImage} />
        <Box className={styles.companyName} style={{ color: colors.textColor }}>
          {selectedOption.label}
        </Box>
        <img src={doubleArrowsIcon} className={styles.arrowsIcon} />
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <>
          <span
            className={styles.popoverArrow}
            style={{
              borderColor: colors.popoverArrowBorderColor,
              backgroundColor: colors.popoverArrowBackgroundColor,
            }}
          />
          <Stack p={'4px'}>
            {options.map((company, index) => (
              <MenuItem
                key={`company-${index}`}
                className={styles.companyItem}
                onClick={() => handleItemClick(company.value)}
              >
                <Avatar src={company.image} className={styles.companyLogo} />
                <Box className={styles.companyItemName}>{company.label}</Box>
              </MenuItem>
            ))}
          </Stack>
        </>
      </Popover>
    </>
  );
};

export default CompanySelect;
