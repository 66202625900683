export const routes = {
  admin: {
    dashboard: '/admin',
    users: {
      list: '/admin/users',
      new: '/admin/users/new',
      edit: '/admin/users/:id',
    },
    translations: {
      list: '/admin/translations',
    },
    profile: '/admin/profile',
    profileSettings: '/admin/profile?activeTab=2',
    settings: '/admin/settings',
    companies: {
      list: '/admin/companies',
      new: '/admin/companies/new',
      edit: '/admin/companies/:id',
    },
    clients: {
      list: '/admin/clients',
      new: '/admin/clients/new',
      edit: '/admin/clients/:id',
    },
    clientRepresentatives: {
      new: '/admin/clients/:client/representatives',
      edit: '/admin/clients/client-representatives/:id',
    },
    projects: {
      list: '/admin/projects',
      new: '/admin/projects/new',
      edit: '/admin/projects/:id',
    },
    employees: {
      list: '/admin/employees',
      new: '/admin/employees/new',
      edit: '/admin/employees/:id',
    },
    tasks: {
      list: '/admin/tasks',
      details: '/admin/tasks/:id',
    },
    clientTasks: {
      list: '/admin/client-tasks',
    },
    invoices: {
      list: '/admin/invoices',
      edit: '/admin/invoices/:id',
    },
    invoiceItems: {
      list: '/admin/invoice-items',
      edit: '/admin/invoice-items/:id',
    },
    taskWorkLogs: {
      list: '/admin/work-logs',
    },
  },
  auth: {
    login: '/login',
    registrationConfirmation: '/registration-confirmation/:token',
    deletionConfirmation: '/deletion-confirmation/:token',
    passwordReset: '/password-reset',
    passwordResetConfirmation: '/password-reset/:token',
  },
  homepage: '/',
};
