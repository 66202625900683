import React, { ReactNode, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, ListItemButton } from '@mui/material';
import styles from './SidebarButton.module.scss';
import cx from 'classnames';
import { useRecoilValue } from 'recoil';
import { sidebarExtendedState } from '../../../../../../atoms/layout/layout.atom';
import { useWindowWidth } from '@react-hook/window-size';
import { colors } from '../../../../../../config/styles/colors';
import { routes } from '../../../../../../config/routes/routes';

export type SidebarItem = {
  title: string;
  icon: ReactNode;
  url: string;
  apiUrl?: string;
  enabled?: boolean;
};

type SidebarButtonProps = {
  item: SidebarItem;
};

export const SidebarButton = ({ item }: SidebarButtonProps) => {
  const sidebarExtendedValue = useRecoilValue(sidebarExtendedState);

  const location = useLocation();
  const screenWidth = useWindowWidth();

  const isCollapsed = useMemo(() => {
    if (screenWidth < 1200) {
      return false;
    }

    return !sidebarExtendedValue;
  }, [sidebarExtendedValue, screenWidth]);

  const isActive = useMemo(() => {
    if (item.url === routes.admin.dashboard) {
      return location.pathname === item.url;
    }

    return location.pathname.includes(item.url);
  }, [item, location.pathname]);

  return (
    <NavLink to={item.url} className={styles.sidebarButton}>
      <ListItemButton
        className={cx(styles.itemButton, {
          [styles.active]: isActive,
          [styles.collapsed]: isCollapsed,
        })}
        style={{
          color: isActive ? colors.sidebarColor : colors.textSubtitleColor,
          backgroundColor: isActive ? colors.sidebarBackgroundColor : undefined,
        }}
      >
        <Box component="span" className={styles.iconContainer}>
          {item.icon}
        </Box>
        <Box component="span" className={styles.labelContainer}>
          <Box component="span" className={styles.label}>
            {item.title}
          </Box>
        </Box>
      </ListItemButton>
    </NavLink>
  );
};

export default SidebarButton;
